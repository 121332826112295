import React, { useState, useEffect, useRef } from "react";

import OnBoard from "../../components/onboard/onboard";
import Header from "../../components/header/header";
import "./NewSignUp.css";
import { ToastContainer, toast } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2'

import { axiosApiInstance, AZURE_CLIENT_ID, decryptData, encryptData, EXTRAMILE_SUPERADMIN_EMAIL, failureAlert, SOCKET_URL, successAlert } from "../../helpers/helper";
import { getOrganisationDetailsById } from "../../redux/actions/plansApiActions";
import { sendOtpToUser, signin, getUser } from "../../redux/actions/userAction";
import { getGameServerOtp } from "../../redux/actions/gameDetailAction";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { BASE_URL } from '../../helpers/helper';

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { CiCircleInfo } from "react-icons/ci";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory } from "react-router-dom";
import arrowback from "../../assets/images/arrow-left.svg";
import upload from "../../assets/images/upload.svg";
import remove from "../../assets/images/remove.svg";
import premiumplan from "../../assets/images/premiumplan.svg";
import countries from "countries-cities";
import Select from "react-select";

import { uploadFile } from "../../redux/actions/commonActions";
import { addOrgDetails } from "../../redux/actions/plansApiActions";
import { getOrganisation } from "../../redux/actions/plansApiActions";
import PaymentSuccessModal from "../../components/modal/paymentsuccess";
import SelectPaymentModal from "../../components/modal/selectpaymentmodal";
import * as ActionTypes from "../../redux/constants/commonApiConstants";
import ExtraMilePlay from '../../assets/images/ExtramilPlay.png';


import { io } from "socket.io-client";
import { updateOrganisation } from "../../redux/actions/organisationActions";
import { SYMBOLS } from "../../constants";
import { useParams } from 'react-router-dom';
import NonLoginUserSuccessModal from "../../components/modal/nonLogInSuccessModal";
const NewSignUp = (props) => {
  const [otpError, setOtpError] = useState('');
  const { plan_id } = useParams();
  const [loaded, setLoaded] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const fileUpload = useSelector((state) => state.uploadedFile);
  const { uploadedFile } = fileUpload;
  const UserInfo = useSelector((state) => state.getUser);
  const { userInfo } = UserInfo;
  const GetOrganisation = useSelector((state) => state.getOrganisation);
  const { orgDetailsByEmail } = GetOrganisation;
  const AddOrgDetails = useSelector(state => state.addOrgDetails);
  const GetPlanDetails = useSelector(state => state.getPlanDetails);
  const userSignin = useSelector((state) => state.userSignin);
  const OfflinePaymentCheckout = useSelector(state => state.offlinePaymentCheckout);
  const UpdateOrganisation = useSelector(state => state.updateOrganisation);

  const [orgNameError, setOrgNameError] = useState("");
  const [validPhone, setValidPhone] = useState(true);
  const handleBackClick = () => {
    history.push("/plans");
  };
  const [disabledOrg, setDisabledOrg] = useState(false);
  const [disablePhone, setDisablePhone] = useState(false);
  const [openPaymentSuccessModal, setOpenPaymentSuccessModal] = useState(false);
  const [openSelectPaymentModal, setOpenSelectPaymentModal] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const [offlinePayment, setOfflinePayment] = useState(false);
  const priceFormatIndianLocale = Intl.NumberFormat('en-IN');
  const [selectedCurrency, setSelectedCurrency] = useState("INR");
  const [discount, setDiscount] = useState(null);
  const [isdiscount, setIsDiscountEnabled] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  var countriesData = countries.getCountries().map((data) => {
    return { value: data, label: data };
  });

  const [selectedPlanInfo, setPlanInfo] = useState(null);
  const [totalPrice, settotalPrice] = useState(null);
  const [totalPriceWithDiscount, settotalPriceWithDiscount] = useState(null);
  const [openNonLoginSuccessModal, setOpenNonLoginSuccessModal] = useState(false);
  const [isSignup, setIsSignup] = useState(true);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  // Assuming discountedPrice is in the same unit as GST
  const validateOtp = (otp) => {
    // Check for numeric OTP and minimum length (e.g., 6 characters)
    const otpRegex = /^[0-9]+$/;
    return otpRegex.test(otp) && otp.length >= 6;
  };

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [companyEmail, setCompanyEmail] = useState(false);
  useEffect(() => {

    // Check if userInfo has changed and set the button state accordingly
    if (userInfo) {

      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [userInfo]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Construct the API URL with the `id`

        const response = await axios.get(BASE_URL + `/api/plan/${plan_id}`);


        if (response?.status == 200) {
         

          setPlanInfo(response.data.data);


        }
      } catch (err) {
        console.log(err);
      }
    }

    // Only call fetchData if `id` is available
    if (plan_id) {
      fetchData();
    }
  }, [plan_id]);


  useEffect(() => {

    const sessionDiscount = sessionStorage.getItem('discount');

    if (sessionDiscount) {
      setDiscount(parseFloat(sessionDiscount));
      setIsDiscountEnabled(true)
    }
  }, [isSignup]);

  useEffect(() => {
  if (!openPaymentSuccessModal && isOtpVerified) {
    history.push("/");
    }
  }, [openPaymentSuccessModal]);

  useEffect(() => {
    const storedData = sessionStorage.getItem('companyInfo');
    if (storedData) {
      const { companyName, email } = JSON.parse(storedData);
      setEmail(email)
      setCompanyEmail(email)
      setFormData(prevData => ({
        ...prevData,
        companyName,
        email
      }));
    
    }
  }, [isSignup]);

  useEffect(() => {
    if (selectedPlanInfo) {
     
      const originalPrice = selectedPlanInfo.prices?.[selectedCurrency];
      const discountedPrice = discount ? originalPrice * (1 - discount / 100) : originalPrice;
      const formattedDiscountedPrice = Math.round(discountedPrice);
      const formattedGstAmount = Math.round(parseFloat(formattedDiscountedPrice) * 0.18);
      settotalPriceWithDiscount(formattedDiscountedPrice)
      settotalPrice(formattedDiscountedPrice + formattedGstAmount);
    }
  }, [selectedPlanInfo]);



  useEffect(() => {
    if (userInfo && !orgDetailsByEmail) {
      dispatch(getOrganisation(userInfo.data.email));
    }
  }, [userInfo]);

  useEffect(() => {
    if (props?.location?.state?.selectedCurrency)
      setSelectedCurrency(props?.location?.state?.selectedCurrency);
    else
      setSelectedCurrency("INR");
  }, [props.location])

  const [orgDetails, setOrgDetails] = useState({
    name: "",
    country: "",
    GSTIN: "",
    phoneNumber: "",
    companyLogo: ""
  });
  useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.phoneNumber && !orgDetailsByEmail) {
      if (userInfo.data.phoneNumber.length > 0) {

        setOrgDetails((prevState) => ({
          ...prevState,
          phoneNumber: userInfo.data.phoneNumber
        }));
        setDisablePhone(true);
      }
    }
  }, [userInfo]);
  useEffect(() => {
    if (orgDetailsByEmail && orgDetailsByEmail.data) {
      setOrgDetails((prevState) => ({
        ...prevState,
        name: orgDetailsByEmail.data.name,
        country: orgDetailsByEmail.data.country,
        GSTIN: orgDetailsByEmail.data.GSTIN,
        phoneNumber: orgDetailsByEmail.data.phoneNumber ? orgDetailsByEmail.data.phoneNumber : userInfo.data?.phoneNumber,
        companyLogo: orgDetailsByEmail.data.companyLogo
      }));
      setDisabledOrg(false);
    }
  }, [orgDetailsByEmail]);







  useEffect(() => {
    return (() => {
      dispatch({ type: ActionTypes.FILE_UPLOAD_SUCCESS, payload: null, });
    })
  }, [])
  
  useEffect(() => {
    if (uploadedFile && uploadedFile.data)
      setOrgDetails((prevState) => ({
        ...prevState,
        companyLogo: uploadedFile.data.path
      }));
  }, [uploadedFile]);
  const [otpemail, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpRequested, setOtpRequested] = useState(false);
  const [timer, setTimer] = useState(20);
  const [timerActive, setTimerActive] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [canResend, setCanResend] = useState(false);
  const intervalRef = useRef(null);
  useEffect(() => {
    let interval = null;
    if (timerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerActive(false);
      setCanResend(true); // Show resend button when timer ends
      setOtpRequested(false);
      setOtp('')
    }
    return () => clearInterval(interval);
  }, [timerActive, timer]);





  useEffect(() => {
    if (
      (GetOrganisation && GetOrganisation.loading) ||
      (fileUpload && fileUpload.loading) ||
      (UserInfo && UserInfo.loading) ||
      (AddOrgDetails && AddOrgDetails.loading) ||
      (GetPlanDetails && GetPlanDetails.loading) ||
      (userSignin && userSignin.loading) ||
      (OfflinePaymentCheckout && OfflinePaymentCheckout.loading) ||
      (UpdateOrganisation && UpdateOrganisation.loading)
    ) {
      setLoaded(false);
    }
    else {
      setLoaded(true);
    }
  }, [GetOrganisation, fileUpload, UserInfo, GetPlanDetails, userSignin, OfflinePaymentCheckout, AddOrgDetails, UpdateOrganisation])

  const initialFormData = {
    name: '',
    email: '',
    companyName: '',
    gstin: ''
  };
  const [formData, setFormData] = useState(initialFormData);


  const [errors, setErrors] = useState({
    name: '',
    email: '',
    companyName: '',
    gstin: ''
  });

  const [touched, setTouched] = useState({
    name: false,
    email: false,
    companyName: false,
    gstin: false
  });

  const validate = (fieldName, value) => {
    let error = '';

    switch (fieldName) {
      case 'name':
        if (!value) {
          error = 'Please fill out this field';
        }
        break;
      case 'email':
        if (!value) {
          error = 'Please fill out this field';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = 'Email address is invalid';
        }
        break;
      case 'companyName':
        if (!value) {
          error = 'Please fill out this field';
        }
        break;
      case 'gstin':
        if (!value) {
          error = 'Please fill out this field';
        } else if (!/[A-Z0-9]{15}/.test(value)) {
          error = 'GSTIN should be 15 alphanumeric characters';
        }
        break;
      default:
        break;
    }

    return error;
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // Validate field on change
    setErrors({
      ...errors,
      [name]: validate(name, value)
    });
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({
      ...touched,
      [name]: true
    });

    // Validate field on blur
    setErrors({
      ...errors,
      [name]: validate(name, formData[name])
    });
  };
  const enablePaymentModal = () => {

    if (isButtonEnabled) {
      setOpenSelectPaymentModal(true);

    }
  };
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields on submit
    const newErrors = {
      name: validate('name', formData.name),
      email: validate('email', formData.email),
      companyName: validate('companyName', formData.companyName),
      gstin: validate('gstin', formData.gstin)
    };

    setErrors(newErrors);

    // Check if there are no errors before submitting
    if (!newErrors.name && !newErrors.email && !newErrors.companyName && !newErrors.gstin) {
      setLoaded(false);
      try {
        const result = await axios.post(BASE_URL + "/api/new-signup/register", formData);

        if (result.status === 200) {
          successAlert('Registered Successfully');
          setLoaded(true);
          setFormData(initialFormData);
          setIsSignup(false);
          setEmail(companyEmail)
        } else {
          failureAlert('Something went wrong');
          setLoaded(true);
        }
      } catch (error) {
        console.error('Registration error:', error);
        failureAlert(error.response.data.message);
        setLoaded(true);
      } finally {
        setLoaded(true);
      }
    }
  };

  

  const toggleForm = (e) => {
    e.preventDefault();
    setIsSignup(prevState => !prevState);
    setCanResend(false)
    setTimerActive(false);
    clearInterval(intervalRef.current);
    setOtpRequested(false)
    setLoaded(true);
    setFormData(initialFormData);
  };

  const requestOTP = async (e) => {
    e.preventDefault();

    if (timerActive) {

      if (!validateOtp(otp)) {
        setOtpError('Please enter a valid OTP');
        return;
      }
      setOtpError('');


      if (otp && otp.length === 6) {
        const body = {
          email: otpemail,
          otp: otp,
          inviteId: ''
        }
        setLoaded(false);
        const resStatus = await dispatch(signin(body));
       
        if (resStatus && resStatus.status === 403) {
          failureAlert(resStatus.data.message);
          setLoaded(true);
        }
        if (resStatus && resStatus.status === 200) {
          localStorage.setItem("otpVerified", encryptData(true));
          dispatch(getUser());
          setIsOtpVerified(true);
          if (!window.socket && resStatus.data?.token) {
            window.socket = io.connect(SOCKET_URL, {
              transports: ["websocket", "polling", "flashsocket"],
              reconnect: true,
              auth: {
                token: resStatus.data.token
              }
            });
            window.socket?.on("notification", (notifications) => {
              dispatch({ type: ActionTypes.GET_NOTIFICATION_SUCCESS, payload: notifications, });
            })
            window.socket?.on("connect_error", (err) => {
              console.log(err instanceof Error); // true
              console.log(err.message); // not authorized
              console.log(err.data); // { content: "Please retry later" }
            });
            window.socket?.on("disconnect", (reason) => {
              console.log({ reason });
            })
          }
          setLoaded(true);
          setOpenNonLoginSuccessModal(true);
          setIsSignup(prevState => !prevState);
          setOtp('');
          setEmail('');
          setTimerActive(false);
          clearInterval(intervalRef.current);

        }
        else if (resStatus && resStatus.data) {
          if (resStatus.data.message === 'USER_IS_DELETED')
            setOtpErrorMessage("User is deleted.")
          else if (resStatus.data.message === "USER EXISTS IN OTHER ORGANIZATION")
            setOtpErrorMessage("You are already linked with some other organisation.")
          else if (resStatus.data.message === "INVALID OTP")
            setOtpErrorMessage("Invaild OTP")
          else if (resStatus.data.message === "ACCESS DENIED")
            setOtpErrorMessage("You are not allowed to join");
          else if (resStatus.data.message === "Rate limit exceeded, retry in 15 minutes") {
            // setErrorMessage("You have made maximum unsuccessful attempts, please try again after some time!")
          }
          setLoaded(true);
        }
        else {
          setOtpErrorMessage("Please enter a valid OTP")
          setLoaded(true);
        }
    
      }
      else
        setOtpErrorMessage("Please enter a valid OTP")
      setLoaded(true);
    }
    else {
      if (!validateEmail(otpemail)) {
        setEmailError('Please enter a valid email address.');
        return;
      }

      setEmailError('');
      setLoaded(false);
      const resStatus = await dispatch(sendOtpToUser(otpemail));

      if (resStatus.status == 200) {



        setOtpRequested(true);
        setTimer(20);
        setTimerActive(true);
        // setIsSignup(true); // Toggle to show "Sign up & Pay" button
        setLoaded(true);
      }
      else {
        failureAlert('Something went wrong!');
        setLoaded(true);
      }
    }


  };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmailBlur = () => {
    if (!validateEmail(otpemail)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handleOtpChange = (e) => {
    let otpValue = e.target.value;

    // Allow only numeric input and limit length to 6 characters
    otpValue = otpValue.replace(/[^0-9]/g, '').slice(0, 6);

    setOtp(otpValue);

    // Validate OTP on change
    if (!validateOtp(otpValue)) {
      setOtpError('Please enter a valid OTP');
    } else {
      setOtpError('');
    }
  };
  const handleMouseEnter = () => {
    if (!isButtonEnabled) {
    failureAlert('Please Login to continue');
    }
  };
  return (
    <>
      {
        openNonLoginSuccessModal &&
        <NonLoginUserSuccessModal modalid={"non-login-success-modal"} toggle={openNonLoginSuccessModal} setOpenNonLoginSuccessModal={setOpenNonLoginSuccessModal} />
      }
      {openSelectPaymentModal && (
        <SelectPaymentModal
          toggle={openSelectPaymentModal}
          modalid="payment"
          setOpenSelectPaymentModal={setOpenSelectPaymentModal}
          setOpenPaymentSuccessModal={setOpenPaymentSuccessModal}
          selectedPlanId={
            selectedPlanInfo && selectedPlanInfo.id
          }
          orgName={orgDetails.name}
          phoneNumber={orgDetails.phoneNumber}
          // amount={props.selectedPlanDetails && Math.round(parseFloat(props.selectedPlanDetails.data.prices?.[selectedCurrency] * 1.18))}
         amount={totalPrice}
          // amount={1}
          setOfflinePayment={setOfflinePayment}
          orgDetails={orgDetailsByEmail}
        />
      )}
      {openPaymentSuccessModal && (
        <PaymentSuccessModal
          po
          toggle={openPaymentSuccessModal}
          setOpenPaymentSuccessModal={setOpenPaymentSuccessModal}
          offlinePayment={offlinePayment}
        />
      )}
      <Header {...props} disableHomepageButton={''} onboard inviteId={''} />
      <ToastContainer style={{ marginTop: '4rem' }} position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <div className="newSignupForm">
        <div className="d-flex">
          {/* {isSignup} */}
        {!isOtpVerified && (     <form >
            {isSignup ? (
              <div>
                <label>
                  <span className="fname">Name</span>
                  <div className="input-container">
                    <input type="text" id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={handleBlur} placeholder="Enter name" />
                    {(touched.name || errors.name) && <span className="errmessage">{errors.name}</span>}

                  </div>
                </label>
                <label>
                  <span className="fname">Email</span>
                  <div className="input-container">
                    <input type="text" id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      onBlur={handleBlur} disabled placeholder="Enter  email" />
                    {(touched.email || errors.email) && <span className="errmessage">{errors.email}</span>}
                  </div>
                </label>
                <label>
                  <span className="fname">Company Name</span>
                  <div className="input-container">
                    <input type="text" 
                    id="companyName"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                       placeholder="Enter company name" disabled />
                    {(touched.companyName || errors.companyName) && <span className="errmessage">{errors.companyName}</span>}
                  </div>
                </label>
                <label>
                  <span className="fname">GSTIN</span>
                  <div className="input-container">
                    <input type="text" id="gstin"
                      name="gstin"
                      value={formData.gstin}
                      onChange={handleChange}
                      onBlur={handleBlur} placeholder="Enter GSTIN" />
                    {(touched.gstin || errors.gstin) && <span className="errmessage">{errors.gstin}</span>}
                  </div>
                </label>


                <div className="signupbtn-container">
                  <button className="btn btn-primary" onClick={handleSubmit}>Sign Up</button>
                </div>

              </div>
            ) : (
              <div>


                <label>

                </label>
                <label>
                  <span className="fname">Email</span>
                  <div className="input-container">
                    <input type="text" id="email1"
                      value={otpemail}
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={handleEmailBlur}
                      placeholder="Enter registered email" disabled/>
                    {emailError && <span className="errmessage">{emailError}</span>}
                
                  </div>

                </label>

                {otpRequested && (<label>
                  <span className="fname">Verify OTP</span>
                  <div className="input-container">
                    <input type="text"
                      id="otp"
                      value={otp}
                      onChange={handleOtpChange}
                      maxLength="6"
                      placeholder="Enter OTP" />
                    {otpError && <span className="errmessage">{otpError}</span>}
                    {/* <p>Enter the 6 digit otp received on test@gmail.com</p> */}
                  </div>
                </label>
                )}

                {canResend && !otpRequested && <label>
                  <span className="fname"></span>
                  <div className="input-container">
                    <span className="errmessage" onClick={requestOTP} style={{ cursor: 'pointer' }}> Resend OTP</span>

                  </div>
                </label>}
                {timerActive && <label>
                  <span className="fname"></span>
                  <div className="input-container">

                    <span className="errmessage">Resend in: {timer}s</span>
                  </div>
                </label>}

                {isSignup ? (
                  <div className="signupbtn-container">
                    <button className="btn btn-primary" onClick={handleSubmit}>Sign up </button>
                  </div>
                ) : (
                  <div className="signupbtn-container">
                    <button className="btn btn-primary" onClick={requestOTP}> {otpRequested ? (timerActive ? 'Verify OTP' : 'Request OTP') : 'Request OTP'}</button>
                  </div>
                )}

              </div>
            )}

            <div className="loginbtn-container">
              <p>Already have an Account?</p>
              <button className="btn btn-primary" onClick={toggleForm}>{!isSignup ? 'Signup' : 'Login'}</button>
            </div>

          </form>)}
   

          <div className="checkoutcard">
            <div className="checkoutcardheading" style={{ marginBottom: '0px' }}>
              <div className="premium-plan-left">
                <h5>  {selectedPlanInfo &&
                  selectedPlanInfo.title} Plan</h5>
                <span>    {selectedPlanInfo &&
                  selectedPlanInfo.validityPeriod} Months</span>
              </div>
              <div className="premium-plan-right">
                <img src={premiumplan} alt="img" />
              </div>
            </div>
            <div className="premium-review">
              <div className="ribbon-3">{discount}% Discount</div>
            </div>
            <div className="premium-review">
              <div className="premium-review-group">
                <div className="premium-review-card1">
                  <span>No of Users</span>
                  <h5> {selectedPlanInfo &&
                    selectedPlanInfo.userLimit} </h5>
                </div>
              </div>
              <div className="premium-review-group subtotal">
                <div className="premium-review-card">
                  <span>Sub-total</span>
                  <h5>   {SYMBOLS[selectedCurrency] + " "}
                    {selectedPlanInfo &&
                      priceFormatIndianLocale.format(Math.round(selectedPlanInfo.prices?.[selectedCurrency]))}</h5>
                </div>
                <div className="premium-review-card">
                  <span>Amount with {discount}% Discount</span>
                  <h5 style={{ textDecoration: isdiscount ? 'line-through' : 'none' }}>
                    {SYMBOLS[selectedCurrency] + " "}
                    {selectedPlanInfo &&
                      priceFormatIndianLocale.format(Math.round(selectedPlanInfo.prices?.[selectedCurrency]))}
                  </h5>

                  {isdiscount && <h5>
                    {SYMBOLS[selectedCurrency] + " "}
                    {selectedPlanInfo &&
                      totalPriceWithDiscount
                    }

                  </h5>
                  }
                </div>
                <div className="premium-review-card">
                  <span>GST 18%</span>
                  <h5>     {SYMBOLS[selectedCurrency] + " "}
                    {selectedPlanInfo &&
                      priceFormatIndianLocale.format(Math.round(parseFloat(totalPriceWithDiscount) * 0.18))}</h5>
                </div>

              </div>
              <div className="premium-total" style={{ marginBottom: '5px' }}>
                <span>Total to be paid</span>
                <h5>
                  {/* {SYMBOLS[selectedCurrency] + " "}
                  {selectedPlanInfo &&
                    totalPrice
                  } */}
                   {SYMBOLS[selectedCurrency] + " "}
                    {selectedPlanInfo &&
                      priceFormatIndianLocale.format(Math.round(totalPrice))}
                </h5>
              </div>
              <div className="premium-button">
                <button onClick={enablePaymentModal} className="btn btn-primary"  onMouseEnter={handleMouseEnter} >Pay Securely</button>

              </div>
            </div>
          </div>
        </div>
      </div>

    </ >
  )
}

export default NewSignUp