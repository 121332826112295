import React from 'react';

import './modal.css';
import Modal from './modal';
import { useState } from 'react';
import submit from '../../assets/images/submit.gif';
import PhoneInput from 'react-phone-input-2';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addDemoRequest } from '../../redux/actions/commonActions';
import { failureAlert } from '../../helpers/helper';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import success from '../../assets/images/successcheck.svg';
import { Link } from 'react-router-dom';
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EmailExistModal = ({ modalid, toggle, setopenEmailExistModal }) => {


    return (
        <Modal modalid={modalid} toggle={toggle}>
        <div className="modal-body">
            <ToastContainer position='bottom-center' />
            <div className="demo-modal-body">
                <div className="close-icon" data-dismiss="modal" aria-label="Close">
                    <div className="close-btn-icon" onClick={() => setopenEmailExistModal(false)}></div>
                </div>
    
                <div className="contact-extramile">
                    <img src={success} alt="msg" />
                    <h5>Error</h5>
                    <span>This email is currently in use by a different organization.</span>
                </div>
    
              
            </div>
        </div>
    </Modal>
    
    );
};
export default EmailExistModal;
