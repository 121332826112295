import React, { useEffect, useState } from 'react';

import './modal.css';
import Modal from './modal';
import { BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';

const AddTeamModal = ({ modalid, toggle, setOpenAddTeamModal, }) => {


    const [inputFields, setInputFields] = useState([]);
    const [loaded, setLoaded] = useState(true)

    const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
    const { orgTeams } = getAllOrgsTeam;
    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;

    const dispatch = useDispatch();



    const handleInputChange = (value) => {
        setInputFields([value]);
    };

    const handleNextClick = async (e) => {
        e.preventDefault()

        if (inputFields.length > 0 && inputFields[0] !== "") {


            if (userInfo && userInfo.data && userInfo.data.organizationId) {
                setLoaded(false)
                const body = {
                    teamName: inputFields[0],
                    organizationId: userInfo.data.organizationId
                }
                const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/create-org-team", body);
                if (response && response.data && response.data.message === "Sucessfully create Team") {
                    successAlert("Sucessfully create Team")
                    setOpenAddTeamModal(false);
                    dispatch(getOrgTeams(userInfo.data.organizationId))
                    setLoaded(true)

                }
                else {
                    failureAlert("Failed to create teams")
                    setLoaded(true)

                }

            }

        }
        else {

            failureAlert("Please enter teams names")
        }

    };


    return (
        <Modal modalid={modalid} toggle={toggle}>
            <LoadingComponent loaded={loaded} />
            <ToastContainer position="bottom-center" />
            <div className="modal-body">
                <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={() => {setOpenAddTeamModal(false); setInputFields([]);}} >
                    <div className="close-btn-icon" ></div>
                </div>
                <div className="thankyou-body users-added-body p-[40px]">
                    <div className="flex items-center w-full">
                        <h4 className='title text-[34px]'>Team Name </h4>

                    </div>

                    <div className="flex items-center w-full">
                    {/* <h2 className='text-[17px] font-semibold'>Team Name</h2> */}
                    </div>

                    <div className="flex items-center w-full rounded-[6px]" style={{border:'0.5px solid #7f7f7f'}}>
                        <input type="text" className="input-text-field rounded-[6px] h-[46px] " placeholder="Enter Team Name"
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-row w-full justify-center items-center" style={{ margin: '15px' }}>
                    <button type="submit" className="btn btn-secondry h-[32px] w-[100px] mr-7" onClick={() => {setOpenAddTeamModal(false); setInputFields([]);}}>Cancel</button>

                        <button type="submit" className="btn btn-primary h-[32px] w-[100px]" onClick={(e) => handleNextClick(e)}>Create</button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default AddTeamModal;
