import React, { useState } from 'react';
import './accordion.css'; // Import your CSS file

const Accordion = ({ items }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleItem = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(null); // Collapse the item if it's already expanded
    } else {
      setExpandedIndex(index); // Expand the clicked item
    }
  };

  return (
    <div className="accordion" style={{marginTop:'1rem'}}>
      {items.map((item, index) => (
        <div className="accordion-item" key={index}>
          <div
            className={`accordion-title ${expandedIndex === index ? 'open' : ''}`}
            onClick={() => toggleItem(index)}
          >
            {item.title}
          </div>
          <div className={`accordion-content ${expandedIndex === index ? 'open' : ''}`}>
            {item.content}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
