import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineCloseCircle, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { FaSearch } from 'react-icons/fa';
import _debounce from "lodash/debounce";
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import "./search.css"
import { updateFilters, updateTeamsGamesFilters } from '../../redux/actions/commonActions';
import offline from '../../assets/images/off_line.png';
import hybrid from '../../assets/images/hy_brid.png';
import online from '../../assets/images/online.png';

const gameTypeOptions = [{ name: "ONLINE", label: "Online", image: online },
{ name: "ONSITE", label: "Onsite", image: offline },
{ name: "HYBRID", label: "Hybrid", image: hybrid }]

export const MobileSearch = ({ searchText, setSearchText, debouncedSearch }) => {
    const searchInputRef = useRef(null);
    const handleSearchTextChange = useMemo(() =>
        AwesomeDebouncePromise(debouncedSearch, 300)
        , [])

    useEffect(() => {
        handleSearchTextChange(searchText)
    }, [searchText])

    return (
        <div
            className="relative w-full">
            <input
                className={` h-[50px] pl-[8px] pr-[35px] min-w-[250px] w-full text-ellipsis font-medium font-base text-gray750 rounded-[4px] py-2 border  bg-white mobile-search-input`}
                type="text"
                name="search"
                value={searchText}
                // onChange={handleSearchTextChange}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder={"Search"}
                autoComplete="off"
            />
            <FaSearch onClick={() => {
                if (searchInputRef?.current) {
                    searchInputRef.current.focus();
                }
            }} size={18} className='absolute right-[13px] top-[15px] text-gray750' />
        </div>
    )
}
const TeamGameSearch = ({ searchText, setSearchText, debouncedSearch }) => {

    const [isOpen, setIsOpen] = useState({
        type: false,
        duration: false,
        headcount: false
    });
    const [headcountValue, setHeadcountValue] = useState(0);
    const [selectedTypeFilters, setSelectedTypeFilters] = useState('');
    const [selectedDurationFilters, setSelectedDurationFilters] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [disableInput, setDisableInput] = useState(false)
    const dispatch = useDispatch();
    const searchContentRef = useRef(null);
    const searchInputRef = useRef(null);
    const filters = useSelector(state => state.filter.filters);

    const handleFocus = () => {
        setIsExpanded(true)
    };

    const handleClickOutside = (e) => {
        if (searchContentRef.current && !searchContentRef.current.contains(e.target)) {
            setIsExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleFilterChange = (filterName, filterValue) => {
        const updatedFilters = {
            ...filters,
            [filterName]: filterValue
        };
        dispatch(updateTeamsGamesFilters(updatedFilters));
    };



    const filterRef = useRef(null);

    const toggleFilterList = (filterType, e) => {
        e.stopPropagation();
        setIsOpen((prevIsOpen) => ({

            [filterType]: !prevIsOpen[filterType]
        }));
    };
    const handleHeadcountFilterChange = (value) => {
        setHeadcountValue(Number(value));
        handleFilterChange('maxPlayers', value);
    };

    const handleSearchTypes = async (e, value) => {

        if (selectedTypeFilters === "ONLINE" && value !== "HYBRID" && value !== "OFFLINE") {
            e.preventDefault();
            setSelectedTypeFilters('');
            handleFilterChange('gameType', '');
        }
        else if (selectedTypeFilters === "HYBRID" && value !== "ONLINE" && value !== "OFFLINE") {
            e.preventDefault();
            setSelectedTypeFilters('');
            handleFilterChange('gameType', '');
        }
        else if (selectedTypeFilters === "OFFLINE" && value !== "ONLINE" && value !== "HYBRID") {
            e.preventDefault();
            setSelectedTypeFilters('');
            handleFilterChange('gameType', '');
        }
        else {

            if (value === "ONSITE") {
                setSelectedTypeFilters("OFFLINE");
                handleFilterChange('gameType', "OFFLINE");
            }
            else {
                setSelectedTypeFilters(value);
                handleFilterChange('gameType', value);
            }

        }

    }

    const handleTypeFilterCheckboxChange = (e, value) => {
        e.stopPropagation();
        console.log("value: "+ value);
        
        if (value === "ONSITE") {
            handleSearchTypes(e, "OFFLINE");
        }
        else {
            handleSearchTypes(e, value);
        }
    };

    const handleDurationFilterCheckboxChange = (e, filterValue) => {
        e.stopPropagation();
        // handleSearchDuration(e, value);
        if (e.target.checked) {
            if (selectedDurationFilters[0] === filterValue) {
                e.preventDefault();
                setSelectedDurationFilters([]);
                handleFilterChange('duration', []);

            }
        } else {
            setSelectedDurationFilters([filterValue]);
            setSelectedDurationFilters((prevSelectedFilters) => {
                handleFilterChange('duration', prevSelectedFilters);
                return prevSelectedFilters;
            });
        }
    };

    const handleSearchDuration = async (e, filterValue) => {
        if (selectedDurationFilters[0] === filterValue) {
            e.preventDefault();
            setSelectedDurationFilters([]);
            handleFilterChange('duration', []);

        }
        else {
            setSelectedDurationFilters([filterValue]);
            setSelectedDurationFilters((prevSelectedFilters) => {
                handleFilterChange('duration', prevSelectedFilters);
                return prevSelectedFilters;
            });
        }
    }


    const handleSearchTextChange = useMemo(() =>
        AwesomeDebouncePromise(debouncedSearch, 300)
        , [])

    useEffect(() => {
        handleSearchTextChange(searchText)
    }, [searchText])

    const clearFilters = (filterType, e) => {
        switch (filterType) {
            case 'type':
                setSelectedTypeFilters('');
                handleFilterChange('gameType', '');
                break;
            case 'duration':
                setSelectedDurationFilters([]);
                handleFilterChange('duration', []);
                break;
            case 'headcount':
                setHeadcountValue('');
                handleFilterChange('maxPlayers', '');
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setIsOpen({
                    type: false,
                    duration: false,
                    headcount: false
                });
            }
        };

        window.addEventListener('click', handleOutsideClick);

        return () => {
            window.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const incrementHeadcount = () => {
        if (headcountValue < 100) {
            handleHeadcountFilterChange(headcountValue + 10)
        } else if (headcountValue < 500) {
            handleHeadcountFilterChange(headcountValue + 100)
        }
    };

    const decrementHeadcount = () => {
        if (headcountValue > 100) {
            handleHeadcountFilterChange(headcountValue - 100)
        } else if (headcountValue > 0) {
            handleHeadcountFilterChange(headcountValue - 10)
        }
    };
    return (
        <>
            <div
                className={` flex items-center relative justify-between bg-white h-[50px] hadder3 search-container rounded-[4px] w-[755px] transition-all duration-300 ease-in-out`}
            >

                <div className='h-full flex-1 flex items-center truncate'>
                    <div
                        ref={searchContentRef}
                        className={`  relative
                rounded-[4px]  bg-white search-input-element  ${isExpanded ? 'search-input-container-expand  !absolute w-[755px] z-10' : isExpanded === false ? 'search-input-container  !w-[422px]' : ' !w-[422px]'} h-full `} >
                        <input
                            className={` h-[50px] w-[422px] text-ellipsis font-medium font-base text-gray750 rounded-[4px] py-2 border  bg-white ${isExpanded ? '!w-[755px] ' : ''} `}
                            type="text"
                            name="search"
                            ref={searchInputRef}
                            value={searchText}
                            onFocus={handleFocus}
                            onChange={(e) => setSearchText(e.target.value)}
                            placeholder={"Search"}
                            autoComplete="off"
                        />
                        <FaSearch onClick={() => {
                            if (searchInputRef?.current) {
                                searchInputRef.current.focus();
                                setTimeout(() => {
                                    // if (!isExpanded) {
                                    setIsExpanded(true)
                                    // }
                                }, 100)
                            }

                        }} size={18} className='absolute right-[13px] top-[15px] text-gray750' />
                        {/* for close icon */}
                        {/* {searchText?.length ? <span className="text-red-500 absolute top-[10px] right-[5px] cursor-pointer" onClick={() => {
                            setSearchText("")
                            debouncedSearch("")
                        }}>
                            <AiOutlineCloseCircle size={25} onClick={() => clearFilters('headcount')} />
                        </span> : null} */}
                    </div>
                    {/* <div className='h-full w-[165px] flex bg-white text-gray750 items-center justify-center font-bold text-base  headcount'>
                        <button
                            className={`bg-transparent font-medium px-4 py-2 flex-1 cursor-pointer flex items-center  justify-center ${isOpen.headcount ? 'font-bold' : ''}`}
                            onClick={(e) => toggleFilterList('headcount', e)}
                        >
                            <span className='text'> Headcount </span>
                            <div className={`inline-block text ${filters?.maxPlayers ? "hidden" : ""}`}>
                            </div>
                            <div className='inline-block ml-1'>
                                {filters?.maxPlayers ? (
                                    <span className="text-red-500 cursor-pointer">
                                        <AiOutlineCloseCircle onClick={() => clearFilters('headcount')} />
                                    </span>
                                ) : null}
                            </div>

                        </button>
                    </div> */}
                    <div className='h-full w-[165px] flex items-center justify-center font-bold text-base text-gray750  type'>
                        <button
                            className={`bg-transparent font-medium px-4 py-2 flex flex-1 items-center justify-center  cursor-pointer    ${isOpen.type ? 'font-bold' : ''}`}
                            onClick={(e) => toggleFilterList('type', e)}
                        >
                            <span className='text'>Format</span>
                            <div className={`inline-block text ${filters?.gameType?.length > 0 ? "hidden" : ""}`}>
                            </div>
                            {/* <div className='inline-block ml-1 '>
                                {filters?.gameType?.length > 0 && (
                                    <span className="search-filter-exit">
                                        <AiOutlineCloseCircle onClick={(e) => clearFilters('type', e)} />
                                    </span>
                                )}
                            </div> */}
                        </button>
                    </div>
                    <div className='h-full w-[165px] flex text-gray750 items-center justify-center font-bold text-base  duration'>
                        <button
                            className={`bg-transparent font-medium px-4 flex-1 py-2 cursor-pointer flex items-center   justify-center ${isOpen.duration ? 'font-bold' : ''}`}
                            onClick={(e) => toggleFilterList('duration', e)}
                        >
                            <span className='text'> Duration</span>
                            <div className={`inline-block text ${filters?.duration?.length > 0 ? "hidden" : ""}`}>
                            </div>
                            {/* <div className='inline-block ml-1'>
                                {filters?.duration?.length > 0 && (
                                    <span className="search-filter-exit">
                                        <AiOutlineCloseCircle onClick={() => clearFilters('duration')} />
                                    </span>
                                )}
                            </div> */}
                        </button>
                    </div>
                </div>
            </div>
            {isOpen.type && (
                <div
                    ref={filterRef}
                    className={`absolute right-[164px] z-10 max-w-[238px] mt-[49px] css-10o0gg1 bg-gray-50 border rounded-[3px] shadow-md transition-all  ${isOpen.type ? ' animate-slide-in' : ' animate-slide-out'
                        }`}
                >
                    {gameTypeOptions.map((val) => (
                        <div key={val} className={`${selectedTypeFilters.includes(val.name === "ONSITE" ? "OFFLINE" : val.name) ? "bg-pink500" : ""} py-2 css-9vggtc hover:bg-gray-200  gap-[8px] pl-[10px]`} onClick={(e) => handleSearchTypes(e, val.name === "ONSITE" ? "OFFLINE" : val.name)}>
                            <div className='flex flex-row '>
                                <div className='typeImageWrappar w-[40px]'>

                                    <div>
                                        <label class="checkbox-label">
                                            <input type="checkbox" class="checkbox-tow"
                                                checked={selectedTypeFilters.includes(val.name === "ONSITE" ? "OFFLINE" : val.name) ? true : false}
                                                onChange={(e) => handleTypeFilterCheckboxChange(e, val.name)}
                                            />
                                            <div class="svg-icon">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="16px"
                                                    viewBox="0 0 448 512"
                                                >
                                                    <path
                                                        d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                    ></path>
                                                </svg>
                                            </div>
                                            <span class="check-box-container"></span>
                                            {/* One  */}
                                        </label>
                                    </div>


                                    {/* <img
                                        src={val?.image}
                                        className={`type${val.label === "Onsite" ? "Offline" : val.label}`}
                                    /> */}
                                </div>
                                <div className="typefilter-text-cont">
                                    <p className={`text-sm ${selectedTypeFilters.includes(val.name === "ONSITE" ? "OFFLINE" : val.name) ? `font-extrabold` : `font-semibold`} heading text-gray750`}

                                    >{val.label}</p>
                                    <p className="text-xs font-normal heading text-gray750">{val.label === "Online" ? "All participants join remotely using their own device" : val.label === "Hybrid" ? 'Participants can either join remotely or together from a physical location' : 'Participants join together at a physical location'}</p>

                                </div>

                            </div>
                        </div>
                    ))}
                </div>
            )}
            {isOpen.duration && (
                <div
                    ref={filterRef}
                    className={`absolute right-0 max-w-[165px] w-[165px] z-10 mt-[49px] duration-contant bg-gray-50 border rounded-[3px] shadow-md transition-all  ${isOpen.duration ? ' animate-slide-in' : ' animate-slide-out'}`}
                >
                    {[5, 10, 20, 30, 40, 50, 60].map((val) => (
                        <div key={val} className={` border-gray-300 flex py-2 css-9vggtc hover:bg-gray-200 gap-[8px] `}
                            style={{
                                backgroundColor: selectedDurationFilters && selectedDurationFilters.includes(val) ? "rgb(223 178 244)" : ''
                            }}
                            onClick={(e) => handleSearchDuration(e, val)}
                            id='filter-option-cont'
                        >

                            {/* <label className="flex items-center cursor-pointer css-1fv6dt5 "> */}

                            <div >
                                <label class="checkbox-label">
                                    <input type="checkbox" class="checkbox-tow" checked={selectedDurationFilters.includes(val)}
                                        onChange={(e) => handleDurationFilterCheckboxChange(e, val)}
                                    />
                                    <div class="svg-icon">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="16px"
                                            viewBox="0 0 448 512"
                                        >
                                            <path
                                                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                            ></path>
                                        </svg>
                                    </div>
                                    <span class="check-box-container"></span>
                                </label>
                            </div>

                            {/* </label> */}
                            <div className={`text-sm ${selectedDurationFilters.includes(val) ? `font-bold` : `font-normal`} text-center  text-gray750 heading `} id='duration-text-cont' style={{ fontSize: selectedDurationFilters.includes(val) ? '0.930rem' : '0.875rem' }}>
                                {val} min
                            </div>
                        </div>))}
                </div>
            )}
            {isOpen.headcount && (
                <div
                    ref={filterRef}
                    className={`absolute left-[140px] mt-[25px] w-[115px] bg-white border shadow-md transition-all ${isOpen.headcount ? ' animate-slide-in' : ' animate-slide-out'}`}
                >
                    <div className="flex items-center">
                        <MdKeyboardArrowLeft
                            size={40}
                            className={`w-[65px] ml-1 ${headcountValue == 0 ? "cursor-not-allowed" : "cursor-pointer"} text-gray750`}
                            onClick={headcountValue > 0 ? decrementHeadcount : null}

                        />
                        <input
                            type="number"
                            className="px-1 py-1 css-mf6ln1 outline-none font-normal text-gray750 bg-white"
                            placeholder="0"
                            min={1}
                            max={500}
                            disabled
                            value={headcountValue}
                        // onChange={(e) => handleHeadcountFilterChange(e.target.value)}
                        />
                        <MdKeyboardArrowRight size={40}
                            className={`w-[65px] mr-1 ${headcountValue == 500 ? "cursor-not-allowed" : "cursor-pointer"} text-gray750`}
                            onClick={headcountValue < 500 ? incrementHeadcount : null}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(TeamGameSearch);
