import LoadingComponent from "../loader/LoadingComponent";
import { IoCheckbox } from "react-icons/io5";
import { FaCirclePlay } from "react-icons/fa6";
import { TbSquareRoundedCheckFilled } from "react-icons/tb";

import { FaStar } from "react-icons/fa";


import catLeg1 from "../../assets/plan-pg-images/cat-leg-1.png";
import catLeg2 from "../../assets/plan-pg-images/cat-leg-2.png";
import catFace from "../../assets/plan-pg-images/cat-face.png";
import catTail from "../../assets/plan-pg-images/cat-tail.png";
import plan_tick from "../../assets/plan-pg-images/tick-mark.png";



import "./newPlans.css"
import { useEffect, useState } from "react";
import arrowback from "../../assets/images/arrow-back.svg";
import { BASE_URL, axiosApiInstance } from "../../helpers/helper";
import LoginModal from "../modal/loginmodal";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ROLES from "../../helpers/userTypes";

const NewPlans = (props) => {

    const [plans, setPlans] = useState();
    const [loaded, setLoaded] = useState(true);
    const [planTitle, setPlanTitle] = useState('Virtual');
    const [planUserLimit, setPlanUserLimit] = useState(200);
    const [selectedCurrency, setSelectedCurrency] = useState("INR");


    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [disbleSelect, setDisableSelect] = useState(false);
    const [fromMyPlans, setFromMyPlans] = useState(false);

    const [planValidity, setPlanValidity] = useState(1);


    const history = useHistory();
    const { userInfo } = useSelector(state => state.getUser);

    useEffect(() => {
        if (props.location &&
            props.location.state &&
            props.location.state.isPlanValid) {
            setDisableSelect(props.location.state.isPlanValid);
        }

        if (
            props.location &&
            props.location.state &&
            props.location.state.fromMyPlans
        ) {
            setFromMyPlans(true);
        }
    }, [props])

    useEffect(() => {
        if (props && props.userType === ROLES.ORG_ADMIN && props.myPlan && props.myPlan.plan) {
            setPlanValidity(props.myPlan.plan.validityPeriod);
        }
    }, [])

    useEffect(() => {
        if (planTitle !== "Virtual") {
            setPlanUserLimit(100)
        }
        if (planTitle === "Virtual") {
            setPlanUserLimit(200)
        }
    }, [planTitle])


    useEffect(() => {
        const callBack = async () => {
            try {
                const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=e1a29c31b72d4a4a9d0ce93be30400ee')
                if (response?.data?.country_name) {
                    if (response?.data?.country_name === "India")
                        setSelectedCurrency("INR");
                    else
                        setSelectedCurrency("USD");
                }
            } catch (err) {
                console.log(err);
            }
        }
        callBack();
    }, []);



    useEffect(() => {
        if (planTitle && planUserLimit) {
            fetchPlans();

        }

    }, [planTitle, planUserLimit])

    const fetchPlans = async () => {
        setPlans()
        setLoaded(false)
        setTimeout(async () => {
            const response = await axiosApiInstance.get(BASE_URL + `/api/plan/get-new-plans/${planTitle}/${planUserLimit}`);
            if (response && response.data && response.data.message === "New Plans fetched successfully") {
                setPlans(response.data.data);
                setLoaded(true)
            }
            else {
                console.log("Failed to fetch plans");
            }
        }, 300)

    }

    const handlePlansData = async (e, title) => {
        e.preventDefault();
        setPlanTitle(title)

    }
    const handleSelectChange = async (e) => {
        setPlanUserLimit(e.target.value)
    }

    const handlePurchase = (e, plan) => {
     
        const discount = sessionStorage.getItem('discount');

        if (discount && !userInfo) {
            history.push({
                pathname: '/new-signup/' + plan.id,
            });
        }
        else {
            if (userInfo) {
                history.push({
                    pathname: '/purchase/' + plan.id,
                    state: { selectedCurrency: selectedCurrency }
                });
            }
            else {
                setOpenLoginModal(true);
            }
        }


    }

    const formatFeatureText = (text) => {
        const capitalizedText = capitalizeFirstLetter(text);
        const words = capitalizedText.split(' ');
        const firstTwoWords = words.slice(0, 2).map((word, index) => {
            if (index === 1 && word.length === 2) {
                return word.toUpperCase();
            }
            return word;
        }).join(' ');
        const restOfWords = words.slice(2).join(' ');
        return `<strong>${firstTwoWords}</strong> ${restOfWords}`;
    };

    const perMonthCost = (planPrice, validMonth, userLimit) => {
        let cost = planPrice / validMonth;
        return Math.floor(cost);
    }

    const perUserCost = (planPrice, validMonth, userLimit) => {
        let cost = planPrice / validMonth / userLimit;
        return cost.toFixed(2);
    }

    // const formatIndianCurrency = (num) => {
    //     let [integerPart, decimalPart] = num.toString().split('.');

    //     integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/(\d+)(?=(\d{2})+(\d)(?!\d))/g, "$1,");

    //     return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    // };

    const formatIndianCurrency = (num, currency) => {
        let [integerPart, decimalPart] = num.toString().split('.');

        if (currency === 'INR') {
            integerPart = integerPart
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .replace(/(\d+)(?=(\d{2})+(\d)(?!\d))/g, "$1,");
        } else if (currency === 'USD') {
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
    };

    // function capitalizeFirstLetter(str) {
    //     if (str.includes('_')) {
    //         return str
    //             .toLowerCase()
    //             .split('_')
    //             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    //             .join('-');
    //     }
    //     else if (str) {
    //         return str.toLowerCase().charAt(0).toUpperCase() + str.toLowerCase().slice(1);

    //     }
    //     else {
    //         return ""
    //     }
    // }
    function capitalizeFirstLetter(str) {
        if (!str) {
            return "";
        }

        return str
            .toLowerCase()
            .split(/[_\s]+/)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const handleBackClick = () => {
        history.push("/account/plan-details");
    };

    useEffect(() => {
        const containers = document.querySelectorAll('.plan-box-container');

        const handleClick = (event) => {
            containers.forEach(box => {
                box.classList.remove('hover-animation');
                // box.classList.remove('reverseHover-animation');
            });
            event.currentTarget.classList.toggle('hover-animation');
            // event.currentTarget.classList.toggle('reverseHover-animation');

        };

        containers.forEach(box => {
            box.addEventListener('click', handleClick);
        });

    }, [plans]);

    return (
        <div className="newplans-container">
            <LoadingComponent loaded={loaded} />
            {openLoginModal && (
                <LoginModal
                    modalid="loginmodal"
                    toggle={openLoginModal}
                    setOpenLoginModal={setOpenLoginModal}
                    plan
                />
            )}

            {
                fromMyPlans &&
                <div className="back-arrow" style={{ margin: '0px', width: '100%', height: '0px' }} id="plan-back-arrow">
                    <img src={arrowback} alt="back" onClick={handleBackClick} />
                    <br /><br />
                </div>
            }
            {props && props.userType !== ROLES.ORG_ADMIN &&
                <div className="heading-info-cont">
                    {/* <h1>Choose <span>Transparent</span> Pricing</h1> */}

                    <h1>Pricing <span>That’s</span> Simple And <span>Clear</span></h1>
                    <span>No Surprises. No Hidden Fee</span>
                </div>

            }

            <div className="newplan-options-container">
                <div className="plan-types-btn-cont">
                    <button type="button"
                        className="plan-option-btn"
                        onClick={(e) => handlePlansData(e, "Virtual")}
                        style={{
                            background: planTitle === 'Virtual' ? 'var(--color-theme_7)' : '',
                            color: planTitle === 'Virtual' ? 'white' : '',
                            boxShadow: planTitle === 'Virtual' ? '0px 8px 24px 2px rgba(0, 0, 0, 0.16)' : ''
                        }}
                    >
                        Virtual
                    </button>

                    {
                        selectedCurrency === "INR" ? (
                            <><button type="button"
                                className="plan-option-btn"
                                onClick={(e) => handlePlansData(e, "Hybrid")}
                                style={{
                                    background: planTitle === 'Hybrid' ? 'var(--color-theme_7)' : '',
                                    color: planTitle === 'Hybrid' ? 'white' : '',
                                    boxShadow: planTitle === 'Hybrid' ? '0px 8px 24px 2px rgba(0, 0, 0, 0.16)' : ''
                                }}
                            >
                                Hybrid
                            </button>

                                <button type="button"
                                    className="plan-option-btn"
                                    onClick={(e) => handlePlansData(e, "Onsite")}
                                    style={{
                                        background: planTitle === 'Onsite' ? 'var(--color-theme_7)' : '',
                                        color: planTitle === 'Onsite' ? 'white' : '',
                                        boxShadow: planTitle === 'Onsite' ? '0px 8px 24px 2px rgba(0, 0, 0, 0.16)' : ''

                                    }}
                                >
                                    On-site
                                </button>
                            </>)
                            : null
                    }
                </div>
                <div className="plan-user-option-cont">
                    <span style={{ fontFamily: "FiraSans-Regular", fontSize: '18px', fontWeight: '600', lineHeight: '21.6px', letterSpacing: '0.5200000524520874px' }}>No.of.Users</span>
                    <div className="user-selection-cont">
                        <select name="options" id="options" style={{ paddingLeft: '5px', border: '1px solid var(--color-theme_7)', fontSize: '18px', fontWeight: '600', lineHeight: '21.6px', letterSpacing: '0.5200000524520874px' }} onChange={handleSelectChange} value={planUserLimit}>
                            {
                                planTitle === 'Virtual' ? (<>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                    <option value="1000">1000</option>
                                </>
                                ) : (<>
                                    <option value="100">100</option>
                                    <option value="300">300</option>
                                    <option value="500">500</option>
                                </>
                                )
                            }
                        </select>
                    </div>

                </div>
            </div>


            <div className="plan-info-container">
                {
                    plans && plans.length > 0 &&
                    plans.map((plan, index) => {
                        return (
                            <div className={`plan-box-container ${index === 3 ? 'hover-animation' : ''}`} >
                                <div className="cat-face-cont">
                                    <img src={catFace} alt="cat-face" />
                                </div>
                                <div className="cat-tail-cont">
                                    <img src={catTail} alt="cat-tail" />
                                </div>
                                <div className="cat-leg1-cont">
                                    <img src={catLeg1} alt="cat-leg1" />
                                </div>
                                <div className="cat-leg2-cont">
                                    <img src={catLeg2} alt="cat-leg2" />
                                </div>

                                <div className="plan-cart-box"
                                    style={{
                                        border: index === 3 ? "1px solid var(--color-theme_7)" : '',
                                        // boxShadow: index == 2 ? "0px 8px 24px 2px rgba(0, 0, 0, 0.16)" : ''
                                    }}
                                >
                                    <div className="plan-type-info">
                                        <span>
                                            {/* {plan.title} / {`${plan.userLimit} Users`} /  */}
                                            {capitalizeFirstLetter(plan.planType)}
                                        </span>
                                    </div>

                                    <div className="per-user-price-cont">
                                        <div className="currency-symbol-cont">
                                            {
                                                selectedCurrency && selectedCurrency === "INR" ? "₹" : '$'
                                            }
                                        </div>
                                        <span>{formatIndianCurrency(perMonthCost(plan.prices[selectedCurrency], plan.validityPeriod, plan.userLimit), selectedCurrency)}</span>
                                        <div className="per-person-info">
                                            <span>per month</span>
                                        </div>
                                    </div>

                                    <div className="plan-description-cont">
                                        {
                                            index === 3 ? (
                                                <div className="our-pick">
                                                    <FaStar fill="white"
                                                        style={{
                                                            height: '16px',
                                                            width: '16px',
                                                            marginLeft: '5px'
                                                        }}
                                                    />
                                                    <span>Our Pick</span>

                                                </div>
                                            ) : null
                                        }

                                        <h3>Enjoy Access To</h3>
                                        {
                                            plan?.description && plan?.description.map((ele, idx) => {
                                                return (
                                                    <div className="plan-descr-info">
                                                        {/* <div className="content-check-cont" style={{display: index === 2 ? 'none' : ''}}>
                                                            <IoCheckbox fill="#34C759" style={{
                                                                height: '24px',
                                                                width: '24px'
                                                            }} />
                                                               <FaCirclePlay
                                                                fill="var(--background-theme)" style={{
                                                                    height: '24px',
                                                                    width: '24px'
                                                                }}
                                                            />


                                                        </div> */}
                                                        <div className="content-checkhover-cont">

                                                            <svg
                                                                stroke="currentColor"
                                                                fill="#34C759"
                                                                strokeWidth="2"
                                                                viewBox="0 0 24 24"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                height="1em"
                                                                width="1em"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                style={{ height: '20px', width: '20px' }}
                                                            >
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M12 2c-.218 0 -.432 .002 -.642 .005l-.616 .017l-.299 .013l-.579 .034l-.553 .046c-4.785 .464 -6.732 2.411 -7.196 7.196l-.046 .553l-.034 .579c-.005 .098 -.01 .198 -.013 .299l-.017 .616l-.004 .318l-.001 .324c0 .218 .002 .432 .005 .642l.017 .616l.013 .299l.034 .579l.046 .553c.464 4.785 2.411 6.732 7.196 7.196l.553 .046l.579 .034c.098 .005 .198 .01 .299 .013l.616 .017l.642 .005l.642 -.005l.616 -.017l.299 -.013l.579 -.034l.553 -.046c4.785 -.464 6.732 -2.411 7.196 -7.196l.046 -.553l.034 -.579c.005 -.098 .01 -.198 .013 -.299l.017 -.616l.005 -.642l-.005 -.642l-.017 -.616l-.013 -.299l-.034 -.579l-.046 -.553c-.464 -4.785 -2.411 -6.732 -7.196 -7.196l-.553 -.046l-.579 -.034a28.058 28.058 0 0 0 -.299 -.013l-.616 -.017l-.318 -.004l-.324 -.001zm2.293 7.293a1 1 0 0 1 1.497 1.32l-.083 .094l-4 4a1 1 0 0 1 -1.32 .083l-.094 -.083l-2 -2a1 1 0 0 1 1.32 -1.497l.094 .083l1.293 1.292l3.293 -3.292z" fill="#34C759" strokeWidth="0"></path>
                                                            </svg>

                                                        </div>
                                                        <span style={{ marginLeft: '10px', fontSize: '16px' }} dangerouslySetInnerHTML={{ __html: formatFeatureText(ele) }}></span>

                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    <span style={{ letterSpacing: '0px', wordSpacing: '0px' }} >{`(${selectedCurrency === "INR" ? "Rs" : "USD"} ${formatIndianCurrency(perUserCost(plan.prices[selectedCurrency], plan.validityPeriod, plan.userLimit), selectedCurrency)} per user/month)`}</span>
                                    {props && props.userType !== ROLES.ORG_ADMIN &&
                                        <div className={`${disbleSelect ? "btn-tooltip" : ""}`}
                                            style={{
                                                width: '100%',
                                                margin: '0px'
                                            }}
                                        >
                                            <button type="button" className={`plan-btn ${disbleSelect ? "disabled cursor-not-allowed" : ""}`} disabled={disbleSelect} onClick={(e) => handlePurchase(e, plan)} > Purchase</button>
                                            {disbleSelect ? <div className="tooltip" role="tooltip">
                                                <span>You have an Active plan.<br /> Please Contact Admin.</span>
                                            </div> : null}
                                        </div>
                                    }


                                </div>
                            </div>

                        )
                    })
                }



            </div>
            {
                planTitle === 'Onsite' && plans && plans.length > 0 ? (
                    <span className="mt-8 p-1 mb-3 sm:mb-0 md:mb-0 lg:mb-0 text-sm">On-site offerings are available in the following cities: Mumbai, Pune, Delhi, Bangalore, Hyderabad, Kolkata, Ahmedabad, and Chennai.</span>

                )
                    : null
            }

        </div>
    )

}

export default NewPlans;