import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import submit from '../../assets/images/submit.gif';
import { useDispatch } from "react-redux";
import axios from "axios";
import { sendOtpToUser, signin, getUser, activateCompany } from "../../redux/actions/userAction";
import { axiosApiInstance, AZURE_CLIENT_ID, decryptData, encryptData, EXTRAMILE_SUPERADMIN_EMAIL, failureAlert, SOCKET_URL, successAlert, BASE_URL } from "../../helpers/helper";
import EmailVerifySuccessModal from "../../components/modal/emailVerifyModal";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import emailVerifyImg from "../../assets/icons/emailVerifyImg.png";
const VerifyOTP = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [otpRequested, setOtpRequested] = useState(false);
    const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
    const [email, setEmail] = useState(null);
    const [emailError, setEmailError] = useState("");
    const [otpError, setOtpError] = useState(""); // State for OTP errors
    const [timer, setTimer] = useState(0); // Timer in seconds
    const [timerActive, setTimerActive] = useState(false); // Whether the timer is active
    const inputRefs = useRef([]);
    const [isAlreadyActivated, setCompanyAlreadyActivated] = useState(false);
    const [openEmailSuccessModal, setOpenEmailVerifySuccessModal] = useState(false);
    const [loaded, setLoaded] = useState(true);
    useEffect(() => {
        const getQueryParams = async () => {
            const params = new URLSearchParams(window.location.search);
            const otp = params.get('otp');
            const emailParam = params.get('email');

            if (emailParam) {
                setEmail(emailParam);
                try {
                    const { data } = await axios.get(`${BASE_URL}/api/organization-plan/isactive?email=${encodeURIComponent(emailParam)}`);
                    setCompanyAlreadyActivated(data.data);
                  
                } catch (error) {
                    console.error('Error fetching organization plan:', error);
                }
            }

            if (otp) {
                setOtpInputs(otp.split(""));
                setOtpRequested(true);
            }
        };

        getQueryParams();
    }, []);




    useEffect(() => {
        let interval = null;
        if (timerActive) {
            interval = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(interval);
                        setTimerActive(false);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        } else if (!timerActive && timer !== 0) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerActive]);

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        const otp = [...otpInputs];

        if (/^\d*$/.test(value)) {
            otp[index] = value;

            if (value && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            }

            if (!value && index > 0) {
                inputRefs.current[index - 1].focus();
            }

            setOtpInputs(otp);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleRequestOtp = async () => {
        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        }

        setEmailError("");
        setLoaded(false);
        const resStatus = await dispatch(sendOtpToUser(email));
        if (resStatus.status === 200) {
            setOtpRequested(true);
            setTimer(300);
            setTimerActive(true);
            setLoaded(true);
            setOtpInputs(["", "", "", "", "", ""]);
        } else {
            failureAlert('Something went wrong!');
            setLoaded(true);
        }
    };

    const handleVerifyOtp = async () => {
        const otp = otpInputs.join('');

        if (otp.length !== otpInputs.length || !/^\d+$/.test(otp)) {
            setOtpError('Please enter a valid OTP.');
            return;
        }

        setOtpError("");


        if (otp && otp.length === 6) {
            const body = {
                email: email,
                otp: otp,
                inviteId: ''
            }
            setLoaded(false);
            const resStatus = await dispatch(signin(body));

            if (resStatus && resStatus.status === 200) {
                localStorage.setItem("otpVerified", encryptData(true));
                dispatch(getUser());
                setOtpRequested(false);
                dispatch(activateCompany(email));
                setOpenEmailVerifySuccessModal(true);
                setLoaded(true);
                history.push({
                    pathname: '/',
                });
            }

            else {
                setOtpError("Please enter a valid OTP")
                setLoaded(true);
            }
        }
        else
            setOtpError("Please enter a valid OTP")
    };

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        if (!validateEmail(newEmail)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    return (
        <div>
            <LoadingComponent loaded={loaded} />
            {
                openEmailSuccessModal &&
                <EmailVerifySuccessModal modalid={"email-modal"} toggle={openEmailSuccessModal} setOpenEmailVerifySuccessModal={setOpenEmailVerifySuccessModal} />
            }
            {isAlreadyActivated ? (<div className="emailverifyoverlay">
  <div className="centeredMessage">
    <img src={emailVerifyImg} alt="Verification Icon" className="messageImage" />
    <p>Email already <span>verified!</span></p>
  </div>
</div>
) : (<div className="emailverify">
                <img src={submit} alt="msg" />
                <div className="title">Email Verification</div>
                {!otpRequested ? (
                    <>
                        <p className="para">Click the button below to request an OTP.</p>
                        <input
                            type="text"
                            className="emailInput"
                            placeholder="Enter email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                        {emailError && <p className="error">{emailError}</p>}
                        <button
                            className="buttonVerify"
                            onClick={handleRequestOtp}
                            disabled={!validateEmail(email)}
                        >
                            Request OTP
                        </button>
                    </>
                ) : (
                    <>
                        <p className="para">Enter the OTP sent to your email</p>
                        <div id="inputs">
                            {otpInputs.map((value, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={value}
                                    className="otpInput"
                                    ref={el => (inputRefs.current[index] = el)}
                                    onChange={e => handleInputChange(e, index)}
                                    pattern="\d*"
                                />
                            ))}
                        </div>
                        {otpError && <p className="error">{otpError}</p>}
                        <button className="buttonVerify" onClick={handleVerifyOtp}>Verify OTP</button>
                        {timerActive && (
                            <div className="timer">
                                Time remaining: {formatTime(timer)}
                            </div>
                        )}
                        {!timerActive && timer === 0 && (
                            <p className="error">OTP has expired. Please request a new one.</p>
                        )}
                    </>
                )}
            </div>)}
        </div>
    );
};

export default VerifyOTP;
