// src/components/FloatingGif.js
import React from 'react';
import './gif.css'; // Import the CSS file for styling
import gifCelebration from '../../assets/icons/celebration.gif'
const FloatingGif = ({ isVisible }) => {
    if (!isVisible) return null; // Don't render the GIF if it's not visible
  
    return (
      <div className="floating-gif">
        <img src={gifCelebration} alt="Floating GIF" />
      </div>
    );
  };

export default FloatingGif;
