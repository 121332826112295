import React, { useEffect, useRef, useState } from 'react';

import './modal.css';
import Modal from './modal';
import { BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import dropDownImg from '../../assets/teams-images/icon down.png'
import Confirm from './confirm';
import { uploadFile } from '../../redux/actions/commonActions';
import { BsUpload } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AddTeamsModal = ({ modalid, toggle, setOpenAddTeamModal, }) => {


    const [numberOfTeams, setNumberOfTeams] = useState(0);
    const [loaded, setLoaded] = useState(true)
    const [openConfirmUploadModal, setOpenConfirmUploadModal] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [file, setFile] = useState(null);

    const [options, setOptions] = useState([1, 2, 3, 4, 5]);
    const [selectOptionsFocused, setselectOptionsFocused] = useState(false)

    const [numOfTeamsCount, setNumOfTeamsCount] = useState(0)

    const addTeamMembersCsv = useRef(null);

    const selectRef = useRef(null);
    const dropdownRef = useRef(null);

    const history = useHistory()

    const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
    const { orgTeams } = getAllOrgsTeam;
    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;

    const dispatch = useDispatch();


    const handleNextClick = async (e) => {
        e.preventDefault()

        if (file && !uploadFlag) {
            setUploadFlag(true)
        }
        else {
            if (numOfTeamsCount > 0 && numOfTeamsCount < 10) {


                if (userInfo && userInfo.data && userInfo.data.organizationId) {
                    setLoaded(false)
                    const body = {
                        numberOfTeams: numOfTeamsCount,
                        organizationId: userInfo.data.organizationId
                    }
                    const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/create-org-team", body);
                    if (response && response.data && response.data.message === "Sucessfully create Team") {
                        successAlert("Sucessfully create Team")
                        setOpenAddTeamModal(false);
                        dispatch(getOrgTeams(userInfo.data.organizationId))
                        setLoaded(true)
                        history.push("/edit/teams")
                    }
                    else {
                        failureAlert("Failed to create teams")
                        setLoaded(true)

                    }

                }

            }
            else {

                failureAlert("Please Select Numbers Of Teams To Create")
            }
        }
    };


    const handleCsvFileClick = () => {
        addTeamMembersCsv.current.click();
    };

    const handleDropDownClick = () => {
        selectRef.current.click();
    };

    useEffect(() => {
        async function callBack() {
            if (uploadFlag) {
                if (file) {
                    const fileData = new FormData();
                    fileData.append("sheets", file);
                    const { status, data } = await dispatch(uploadFile(fileData, true));
                    if (status === 200) {
                        if (data && data.data && data.data.path) {
                            const { path } = data.data;
                            setLoaded(false)
                            const checkUserActivity = await axiosApiInstance.post(BASE_URL + '/api/organization-team/create-org-team/csv', { filePath: path.replace(BASE_URL + "/", "") });

                            if (checkUserActivity.data.message === "Created teams sucessfully") {
                                successAlert("Created teams sucessfully");
                                setOpenAddTeamModal(false);
                                dispatch(getOrgTeams(userInfo.data.organizationId))
                                setLoaded(true)
                                history.push("/edit/teams")


                            }
                            else if (checkUserActivity.data.message === "contain duplicate teams names") {
                                failureAlert("Contain duplicate teams names")
                                setLoaded(true);
                            }

                            else if (checkUserActivity.data.message === "Invalid Fields") {
                                failureAlert("Invald file format")
                                setLoaded(true);
                            }

                            else {
                                failureAlert("Something went wrong")
                                setLoaded(true);
                            }
                            // setSelectedTeamUsersData([]);
                            // setTeams([]);
                            // setTeamLogo('');
                            // setTeamMemberSearch('');
                            // dispatch(getOrgTeams(userInfo.data.organizationId));

                        }
                    }
                    else if (status?.includes?.("417"))
                        failureAlert("Uploaded file contains some malware!");
                    else if (status?.includes?.("500"))
                        failureAlert("File Format Not supported");
                    setFile(null);
                }
                setUploadFlag(false);
            }
        }
        callBack();
    }, [uploadFlag]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setselectOptionsFocused(false); // Close dropdown if clicked outside
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);


    const csvFileChanged = (e) => {
        e.persist();
        const inputFile = e.target.files[0];
        e.target.value = [];
        setFile(inputFile);
        successAlert("File uploaded sucessfully")

    };

    const handleselectFocuse = () => {
        setselectOptionsFocused(true)
    }

    const handleselectBlur = () => {
        setselectOptionsFocused(false)
    }

    const handleAddTeamsCount = (e, item) => {
        e.preventDefault();
        console.log("item " + item);

        setNumOfTeamsCount(item);
        setselectOptionsFocused(false)

    }

    const handleSelectChange = (e) => {
        e.preventDefault();
        let val = e.target.value;
        console.log("val 1  " + val);

        if (val <= 1000) {
            console.log("val 2  " + val);

            setNumOfTeamsCount(val)
        }

    }

    const handleSampleDownload = () => {
        const downloadLink = "https://2023-extramileplay-public.s3.ap-south-1.amazonaws.com/team%2Bnames.csv";

        const anchor = document.createElement('a');
        anchor.href = downloadLink;
        anchor.download = "team-names.csv";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };


    return (
        <Modal modalid={modalid} toggle={toggle}>
            <LoadingComponent loaded={loaded} />
            <ToastContainer position="bottom-center" />
            <div className="modal-body">
                <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={() => setOpenAddTeamModal(false)} >
                    <div className="close-btn-icon" ></div>
                </div>
                <div className="flex items-center justify-start flex-col rounded-[10px] w-[100%] md:w-[100%] lg:w-[875px] h-[484px] p-[18px] md:p-[40px] lg:p-[40px] bg-white  md:pl-[112px] md:pr-[112px] lg:pl-[112px] lg:pr-[112px]">
                    <div className="flex items-center w-full">
                        <h4 className=' text-[24px] md:text-[34px] lg:text-[34px] text-[#23282E] font-semibold'>Create Teams </h4>

                    </div>
                    <div className="flex items-center justify-between w-full p-[20px] bg-[#F6F9FE] flex-row mt-[20px]">
                        <div className="flex flex-col w-auto">
                            <h3 className='text-[16px] md:text-[18px] lg:text-[18px] text-[#23282E] font-sans font-semibold'>Team Settings</h3>
                            <h3 className='text-[13px] md:text-[18px] lg:text-[18px] text-[#696f76] font-sans'>How many teams do you need?</h3>

                        </div>
                        <div className="flex flex-col w-[86px] h-[45px] items-center justify-center bg-white relative">
                            {/* <div className="flex w-[11px] h-[6px] justify-center items-center absolute top-[45%] right-[19px]" onClick={handleDropDownClick}>
                                <img src={dropDownImg} alt="drop-down-img" />
                            </div>
                            <select className=' w-full pl-[30px] border-none outline-none'
                                style={{
                                    appearance: 'none',
                                }}
                                value={numOfTeamsCount}
                                onChange={handleAddTeamsCount}
                                ref={selectRef}
                                placeholder={'0'}
                            >
                                <option style={{
                                    display:'none'
                                }}>0</option>

                                <option value={1}>1</option>
                                <option value={2}>2</option>
                                <option value={3}>3</option>
                                <option value={4}>4</option>
                                <option value={5}>5</option>

                            </select> */}

                            <input
                                type='number'
                                max={1000}
                                onFocus={handleselectFocuse}
                                // onBlur={handleselectBlur}
                                onChange={handleSelectChange}
                                value={numOfTeamsCount}
                                className='w-full h-full focus:outline-none focus:border-none border-none outline-none text-[18px] flex items-center justify-center text-end pr-[40px]'
                            ></input>

                            <div className={` absolute ${selectOptionsFocused ? 'flex' : 'hidden'} flex-col items-center w-[86px] h-max bg-white top-[50px] rounded-[6px] z-10`} style={{
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)'
                            }}
                            ref={dropdownRef}
                            >
                                {
                                    options.map((item) => {
                                        return (
                                            <div className="flex w-full h-[30px] text-[14px] text-center justify-center hover:bg-hoverThemeColor hover:cursor-pointer" 
                                             onClick={(e) => handleAddTeamsCount(e, item)}
                                             >
                                                <span className=' flex items-center justify-center w-full h-[30px] text-[18px] text-center' >{item}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className=" mt-[15px] flex w-full items-center text-[18px] text-[#23282E] font-sans">OR</div>

                    <div className="flex items-center justify-between w-full p-[20px] bg-[#F6F9FE] flex-row mt-[15px]">
                        <div className="flex flex-col w-auto">
                            <h3 className='text-[13px] md:text-[18px] lg:text-[18px] text-[#23282E] font-sans font-semibold'>Upload File <span className='text-blue-500 text-[14px] font-semibold underline ml-1 cursor-pointer' onClick={handleSampleDownload}> Download Sample </span></h3>
                            <h3 className='text-[12px] md:text-[14px] lg:text-[14px] text-[#696f76] font-sans'>Upload CSV file to add users</h3>

                        </div>
                        <div className="flex flex-col w-[50px] md:w-[135px] lg:w-[140px]  h-[45px] items-center justify-center">
                            <div className="flex items-center justify-center h-[100%] btn-tooltip">
                                <button type="submit"
                                    className={` btn btn-secondry w-[50px] md:w-[120px] lg:w-[120px] h-[33px]`}
                                    style={{
                                        padding: '0px',
                                    }}
                                    onClick={handleCsvFileClick}
                                >
                                    <input
                                        type="file"
                                        accept=".csv"
                                        onChange={csvFileChanged}
                                        className="hidden"
                                        ref={addTeamMembersCsv}
                                    />
                                    <BsUpload className=' !mr-[0px] md:!mr-[9px] lg:!mr-[9px]'/>

                                    <span className=" text-[14px] hidden md:flex lg:flex "> Upload Excel</span>
                                </button>

                            </div>
                        </div>
                    </div>


                    <div className="flex flex-row w-full justify-center items-center mt-[25px]">
                        <button type="submit" className="btn btn-secondry h-[32px] w-[100px] mr-7" onClick={() => setOpenAddTeamModal(false)}>Cancel</button>

                        <button type="submit" className="btn btn-primary h-[32px] w-[100px]" onClick={(e) => handleNextClick(e)}>Create</button>
                    </div>
                </div>
            </div>
            {openConfirmUploadModal && (
                <Confirm
                    modalid="uploadcsv"
                    toggle={openConfirmUploadModal}
                    file={file}
                    setOpenConfirmUploadModal={setOpenConfirmUploadModal}
                    setUploadFlag={setUploadFlag}
                />
            )}
        </Modal>
    );
};
export default AddTeamsModal;
