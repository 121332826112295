import React, { useEffect, useRef, useState } from 'react';

import './modal.css';
import Modal from './modal';
import { BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import dropDownImg from '../../assets/teams-images/icon down.png'
import Confirm from './confirm';
import { uploadFile } from '../../redux/actions/commonActions';
import { BsUpload } from 'react-icons/bs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MdErrorOutline } from 'react-icons/md';

const TeamsLogoUpload = ({ modalid, toggle, setOpenAddTeamModal, setSelectedTeamInfo, selectedTeamInfo }) => {


    const [loaded, setLoaded] = useState(true)
    const [uploadFlag, setUploadFlag] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadLogo, setUploadLogo] = useState(false)

    const addTeamMembersCsv = useRef(null);

    const history = useHistory()

    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;

    const fileUpload = useSelector(state => state.uploadedFile);
    const { uploadedFile } = fileUpload;

    const dispatch = useDispatch();



    useEffect(() => {
        const callBack = async (teamLogo) => {

            const body = {
                teamcaptainInfo: null,
                usersTeam: null,
                teamLogoData: {
                    teamId: selectedTeamInfo.id,
                    logo: teamLogo ? teamLogo : null
                },
                teamName: {
                    name: null,
                    teamId: null,
                }
            }

            if (userInfo && userInfo?.data && userInfo?.data?.organizationId) {
                setLoaded(false)

                const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/update-team", body);
                if (response && response.data && response.data.message === "org user Updated sucessfully") {
                    successAlert("sucessfully added user to team");
                    setUploadFlag(false);
                    setSelectedTeamInfo(null)
                    setOpenAddTeamModal(false)
                    dispatch(getOrgTeams(userInfo.data.organizationId))


                }
                else {
                    failureAlert("!Opps something went wrong")
                }
                setSelectedTeamInfo(null);
                setUploadLogo(false)
                setLoaded(true)

            }
        }

        if (uploadedFile && uploadedFile.data && uploadLogo) {
            let isImagePath = isImageFileType(uploadedFile.data.path)
            if (isImagePath) {
                callBack(uploadedFile.data.path)
            }

        }

    }, [uploadedFile, fileUpload])

    const isImageFileType = (path) => {
        const validImageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
        const extension = path.split('.').pop().toLowerCase();
        return validImageExtensions.includes(extension);
    };



    const handleNextClick = async (e) => {
        e.preventDefault()
        console.log("file  " + file);

        if (file && !uploadFlag) {
            setUploadFlag(true);
            setUploadLogo(true)
        }

    };


    const handleCsvFileClick = () => {
        addTeamMembersCsv.current.click();
    };


    useEffect(() => {
        async function callBack() {
            if (uploadFlag) {
                fileChanged()
            }
        }
        callBack()
    }, [uploadFlag])


    const teamLogoChange = (e) => {
        e.persist();
        setLoaded(false)
        const inputFile = e.target.files[0];
        e.target.value = [];


        const img = new Image();
        let width, height;
        img.onload = async function () {
            height = this.height;
            width = this.width;
            // if (e.target.name === "team-logo-upload") {
            if (width === 74 && height === 74) {
                successAlert('uploaded image sucessfully')
                setFile(inputFile);
            }
            else {
                failureAlert("The team logo dimensions should be 74 x 74.")

            }

        }
        const _URL = window.URL || window.webkitURL;
        img.src = _URL.createObjectURL(inputFile);
        setLoaded(true)
    };



    const fileChanged = async (e) => {
        // e.persist();
        // const file = e.target.files[0]
        // e.target.value = null;
        const img = new Image();
        let width, height;
        img.onload = async function () {
            height = this.height;
            width = this.width;
            // if (e.target.name === "team-logo-upload") {
            if (width === 74 && height === 74) {
                const data = new FormData();
                data.append("team-logo", file);
                const response = await dispatch(uploadFile(data));
                if (response?.status?.includes?.("417"))
                    failureAlert("Uploaded file contains some malware!");
                else if (response?.status?.includes?.("500"))
                    failureAlert("File format not supported");
            }
            else {
                failureAlert("The team logo dimensions should be 74 x 74.")

            }
            setUploadFlag(false)
            // }
        }
        const _URL = window.URL || window.webkitURL;
        img.src = _URL.createObjectURL(file);
    }






    return (
        <Modal modalid={modalid} toggle={toggle}>
            <LoadingComponent loaded={loaded} />
            <ToastContainer position="bottom-center" />
            <div className="modal-body">
                <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={() => setOpenAddTeamModal(false)} >
                    <div className="close-btn-icon" ></div>
                </div>
                <div className="flex items-center justify-start flex-col rounded-[10px] w-[100%] md:w-[100%] lg:w-[875px] h-[327pxpx] p-[15px] md:p-[44px] lg:p-[44px] pt-[32px] md:pt-[70px] lg:pt-[70px]  bg-white ">
                    <div className="flex items-center w-full">
                        <h4 className=' text-[24px]text-[#23282E] font-semibold'>Upload Team Logo </h4>

                    </div>

                    <div className="flex items-center justify-between w-full p-[20px] bg-[#F6F9FE] flex-col md:flex-row lg:flex-row mt-[15px] rounded-[6px]">
                        <div className="flex flex-col w-auto">
                            <h3 className='text-[13px] md:text-[16px] lg:text-[16px] text-[#8b8f95] font-sans '>Upload an image as your team logo (74 x 74)</h3>
                            <h3 className=' flex text-[11px] md:text-[14px] lg:text-[14px] text-[#E25569] font-sans mt-1'>
                                <MdErrorOutline style={{ width: '19px', height: '19px', marginRight: '7px' }} />
                                The File Size Should Not Exceed 1MB. (Jpg, PNG)</h3>

                        </div>
                        <div className="flex flex-col w-[100%] md:w-[135px] lg:w-[140px]  h-[45px] items-center justify-center mt-2 md:mt-0 lg:mt-0">
                            <div className="flex items-center justify-center h-[100%] btn-tooltip w-full">
                                <button type="submit"
                                    className={` btn btn-secondry w-[100%] md:w-[120px] lg:w-[120px] h-[33px]`}
                                    style={{
                                        padding: '0px',
                                    }}
                                    onClick={handleCsvFileClick}
                                >
                                    <input
                                        type="file"
                                        accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                        onChange={teamLogoChange}
                                        className="hidden"
                                        ref={addTeamMembersCsv}
                                    />
                                    <BsUpload className=' !mr-[9px] lg:!mr-[9px]' />

                                    <span className=" text-[14px] "> Upload File</span>
                                </button>

                            </div>
                        </div>
                    </div>


                    <div className="flex flex-row w-full justify-center items-center mt-[25px]">
                        <button type="submit" className="btn btn-secondry h-[32px] w-[100px] mr-7" onClick={() => setOpenAddTeamModal(false)}>Cancel</button>

                        <button type="submit" className="btn btn-primary h-[32px] w-[100px]" onClick={(e) => handleNextClick(e)}>Save</button>
                    </div>
                </div>
            </div>

        </Modal>
    );
};
export default TeamsLogoUpload;
